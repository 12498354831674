<template>
  <div>
    <vs-tabs class="invoice-list">
      <vs-tab label="Details">
        <div>
          <basic-users-detail
            fetchClinicsBy="superAdmin"
            :canDelete="true"
            :inTabEdit="true"
            :adminApprovalVar="true"
          />
        </div>
      </vs-tab>
      <vs-tab label="Organizations">
        <associated-organization-table :userId="basicUserId" />
      </vs-tab>
      <vs-tab label="Clinics">
          <div v-if="check_has_permission('viewNurseAssociatedClinics')">
              <associated-clinics
                :associatedIds="
                  basicUserData.associatedClinicIds ? basicUserData.associatedClinicIds.map((e) => e.clinicId) : []
                "
                :inTabEdit="true"
                :userId="this.$route.params.basicUserId"
                userTypeToEdit="nurse"
              />
          </div>
      </vs-tab>
      <vs-tab label="Permissions" name="permissions">
          <PermissionSettings
            userType="basic"
            :userId="basicUserId"
          />
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import basicUsersDetail from "../../components/basic-user/basicUsersDetail.vue";
import AssociatedClinics from "../../components/others/associatedClinics.vue";
import AssociatedOrganizationTable from "../../components/organization/associatedOrganizationTable.vue";
import PermissionSettings from "../../components/roles-permissions/PermissionSettings.vue";
import { mapActions } from "vuex";

export default {
  components: {
    basicUsersDetail,
    AssociatedClinics,
    AssociatedOrganizationTable,
    PermissionSettings,
  },
  data: () => ({
    basicUserId:null,
    basicUserData:{}
  }),
  methods: {
    ...mapActions("nurse", ["fetchNurseDetails"]),
    async getNurseDetail(basicUserId) {
      const res = await this.fetchNurseDetails(basicUserId)
      const nurse = res.data.data;
      this.basicUserData = {
        _id: basicUserId,
        firstName: nurse.firstName,
        lastName: nurse.lastName,
        associatedClinicIds: nurse.associatedClinicIds,
      };
    },
  },
  async created() {
    this.basicUserId = this.$route.params.basicUserId;
    await this.getNurseDetail(this.$route.params.basicUserId);
  },
};
</script>
